<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <ConfigKey_select
          :dense="true"
          outlined
          class="col-2"
          label="选择主模块"
          v-model="CONFIG_KEY_NO"
          v-model:valueCode="CONFIG_KEY_NO"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmConfigTemplateListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmConfigTemplateListLoading" @click="onExportData">导出</q-btn> -->
        <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmConfigTemplateListLoading"
          @click="onAdd"
          >新增</q-btn
        >
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 140"
        :loading="PdmConfigTemplateListLoading"
        highlight-current-row
        size="mini"
        :data="data"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          >
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmConfigTemplateshowDlg" persistent>
    <ConfigTemplateEdit @onLoadData="onLoadData" />
  </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import ConfigTemplateEdit from "./configTemplateEdit.vue";
import ConfigKey_select from "@/views/pdm/comm/configKey_select.vue";
export default {
  components: {
    ConfigTemplateEdit,
    ConfigKey_select,
  },
  computed: {
    ...mapState("WebConfig", ["WebConfigState"]),
    ...mapState("PdmConfigTemplate", [
      "PdmConfigTemplateList",
      "PdmConfigTemplateListLoading",
      "PdmConfigTemplateEntity",
      "PdmConfigTemplateShowDlg",
    ]),

    pdmConfigTemplateshowDlg: {
      get() {
        return this.PdmConfigTemplateShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit(
          "PdmConfigTemplate/setPdmConfigTemplateShowDlg",
          val
        );
      },
    },
  },
  watch: {
    WebConfigState(val) {
      if (val == 1) {
        setTimeout(()=> {
        this.onLoadData();
        this.$store.commit("WebConfig/setWebConfigState", 0);
          }, 500);
       
      }
    },
  },
  data() {
    return {
      FilterString: "",
      CONFIG_KEY_NO:null,
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      data: null,
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "CONFIG_KEY_NAME",
            title: "主模块名称",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "CONFIG_TEM_NAME",
            title: "子模块名称",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "Config_Tem_Memo",
            title: "备注",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "CONFIG_TEM_TYPEname",
            title: "类别",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "CONFIG_TEM_TU",
            title: "子模块图片",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "CONFIG_TEM_NO",
            title: "排列序号",
            sortable: true,
            align: "center",
            width: 100,
          },
           {
            field: "CONFIG_TEM_PATH",
            title: "小程序页面路径",
            sortable: true,
            align: "left",
            width: 200,
          },
          {
            field: "CDATE",
            title: "添加时间",
            sortable: true,
            align: "center",
            width: 140,
          },
          {
            title: "操作",
            width: 200,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmConfigTemplateList,
     
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmConfigTemplate", [
      "actGetPdmConfigTemplateListByPage",
      "actCreatePdmConfigTemplate",
      "actEditPdmConfigTemplateData",
      "actEditPdmConfigTemplate",
      "actDeletePdmConfigTemplate",
    ]),
    onLoadData() {
      this.actGetPdmConfigTemplateListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        CONFIG_KEY_NO:this.CONFIG_KEY_NO,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit(
            "PdmConfigTemplate/setPdmConfigTemplateListLoading",
            false
          );
          //this.PdmConfigTemplateListLoading = false;
        });
    },
    onAdd() {
      this.actCreatePdmConfigTemplate({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmConfigTemplate(obj);
    },
    onDelete(e) {
      this.actDeletePdmConfigTemplate({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
